import React, { useState } from "react";
import { fetchBooks } from "./assets/api/googleBooksApi";
import { fetchFromMercadoLibre } from "./assets/api/mercadoLibreApi";
import logo from "./assets/logo.png";
import banner from "./assets/banner.png";
import googleLogo from "./assets/google-logo.png";
import mercadoLibreLogo from "./assets/mercadolibre-logo.png";
import "./App.css";

const usdToArs = 1250; // Tasa de conversión para precios en USD a ARS

function App() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);

  // Función para truncar títulos largos
  const truncateTitle = (title, maxLength = 60) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  };

  const handleSearch = async () => {
    // Buscar libros en Google Books
    const googleBooksResponse = await fetchBooks(query, startIndex);
    const googleBooks = googleBooksResponse.books;

    // Buscar libros en MercadoLibre
    const mercadoLibreResponse = await fetchFromMercadoLibre(query, startIndex);
    const mercadoLibreBooks = mercadoLibreResponse.books;

    // Combinar los resultados de ambas fuentes
    const combinedResults = [...googleBooks, ...mercadoLibreBooks];

    // Convertir todos los precios a ARS y filtrar aquellos que tienen un precio
    const convertedResults = combinedResults
      .filter((item) => item.saleInfo.listPrice?.amount) // Solo libros con precios
      .map((item) => {
        // Si el precio está en USD (Google Books), lo convertimos a ARS
        const priceInArs =
          item.saleInfo.listPrice.currencyCode === "USD"
            ? item.saleInfo.listPrice.amount * usdToArs
            : item.saleInfo.listPrice.amount; // MercadoLibre ya está en ARS

        return {
          ...item,
          saleInfo: {
            ...item.saleInfo,
            listPrice: {
              ...item.saleInfo.listPrice,
              amountInArs: priceInArs, // Agregamos el precio en ARS
            },
          },
        };
      });

    // Ordenar los resultados por precio en ARS (menor a mayor)
    const sortedResults = convertedResults.sort(
      (a, b) =>
        a.saleInfo.listPrice.amountInArs - b.saleInfo.listPrice.amountInArs
    );

    // Total de resultados de ambas APIs
    const combinedTotalResults =
      googleBooksResponse.totalItems + mercadoLibreResponse.totalItems;

    // Calcular el número total de páginas
    const pages = Math.ceil(combinedTotalResults / 10);

    // Actualizar el estado con los resultados ordenados
    setResults(sortedResults);
    setTotalResults(combinedTotalResults);
    setTotalPages(pages);
  };

  const handleNextPage = () => {
    setStartIndex(startIndex + 10);
    handleSearch();
  };

  const handlePreviousPage = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 10);
      handleSearch();
    }
  };

  // Obtener el logotipo según la fuente del libro
  const getSourceLogo = (source) => {
    if (source === "Google") return googleLogo;
    if (source === "MercadoLibre") return mercadoLibreLogo;
    return null; // Puedes agregar más logos para otras fuentes en el futuro
  };

  return (
    <div className="App">
      <img src={banner} alt="Banner" className="banner" />
      <h1>Encontrá el libro que buscás y al mejor precio</h1>
      <div className="center-container">
        <img src={logo} alt="Bookify logo" className="logo" />
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar por título, autor, editorial o ISBN"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button className="search" onClick={handleSearch}>
            
          </button>
        </div>
      </div>

      {results.length > 0 && (
        <>
          <button
            className="zocalo-borrar"
            onClick={() => {
              setQuery("");
              setResults([]);
            }}
          >
            Borrar
          </button>

          <ul>
            {results.map((item, index) => (
              <li key={index} className="book-row">
                {item.volumeInfo.imageLinks?.thumbnail && (
                  <img
                    src={item.volumeInfo.imageLinks.thumbnail}
                    alt={`Portada de ${item.volumeInfo.title}`}
                    className="book-image"
                  />
                )}

                <div className="info-price-container">
                  <div className="book-info">
                    <h3>{truncateTitle(item.volumeInfo.title)}</h3> {/* Limitar el largo del título */}
                    <p>
                      Autor:{" "}
                      {item.volumeInfo.authors?.join(", ") ||
                        "Autor no disponible"}
                    </p>
                    <p>
                      Fecha de publicación:{" "}
                      {item.volumeInfo.publishedDate || "Fecha no disponible"}
                    </p>
                    <p>ISBN: {item.volumeInfo.isbn}</p> {/* Mostrando ISBN */}
                    <p>Editorial: {item.volumeInfo.publisher}</p> {/* Mostrando Editorial */}
                    <a
                      href={item.volumeInfo.infoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ir a la Tienda
                    </a>
                  </div>

                  <div className="book-price">
                    <p style={{ color: "green", fontSize: "1.1em" }}>
                      ARS: ${" "}
                      {Math.round(
                        item.saleInfo.listPrice.amountInArs
                      ).toLocaleString("es-AR")}
                    </p>
                    {/* Mostrar logotipo de la fuente debajo del precio */}
                    {getSourceLogo(item.volumeInfo.source) && (
                      <img
                        src={getSourceLogo(item.volumeInfo.source)}
                        alt={`${item.volumeInfo.source} logo`}
                        className="source-logo" 
                        style={{ width: "35px", height: "auto", marginTop: "10px" }} // Ajustes de tamaño y margen
                      />
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="zocalo">
            {/* Placeholder del botón "Página anterior" si startIndex es 0 */}
            {startIndex > 0 ? (
              <button
                className="zocalo-button-left"
                onClick={handlePreviousPage}
              >
                Página {Math.floor(startIndex / 10)}
              </button>
            ) : (
              <div className="button-placeholder"></div> // Placeholder que mantiene el espacio
            )}

            <div className="zocalo-resultados">
              <p>Se encontraron {totalResults} resultados</p>
            </div>

            {/* Botón de "Página siguiente" siempre visible */}
            <button className="zocalo-button-right" onClick={handleNextPage}>
              Página {Math.floor(startIndex / 10) + 1} de {totalPages}
            </button>
          </div>
        </>
      )}

      <div className="footer">
        <p>
          Bookify.ar &reg; un Producto de{" "}
          <a
            href="https://sabiduria.ar"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Sabiduría &#10084;&#65039;
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
