import axios from "axios";

const GOOGLE_BOOKS_API_URL = "https://www.googleapis.com/books/v1/volumes";

export const fetchBooks = async (query, startIndex = 0) => {
  try {
    const response = await axios.get(
      `${GOOGLE_BOOKS_API_URL}?q=${query}&startIndex=${startIndex}&maxResults=10`
    );

    const books = response.data.items || [];
    const filteredBooks = books.filter(
      (book) =>
        book.saleInfo.saleability === "FOR_SALE" &&
        book.saleInfo.listPrice?.amount
    );

    // Transformar los resultados de Google Books, incluyendo la fuente "Google"
    const transformedBooks = filteredBooks.map((book) => {
      const isbn = book.volumeInfo.industryIdentifiers?.find(
        (id) => id.type === "ISBN_13"
      )?.identifier || "ISBN no disponible";

      return {
        ...book,
        volumeInfo: {
          ...book.volumeInfo,
          isbn: isbn,
          source: "Google", // Fuente de los datos
        },
      };
    });

    return {
      books: transformedBooks,
      totalItems: response.data.totalItems || 0
    };
  } catch (error) {
    console.error("Error fetching books from Google Books:", error);
    return { books: [], totalItems: 0 };
  }
};
