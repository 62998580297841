import axios from 'axios';

const MERCADO_LIBRE_API_URL = 'https://api.mercadolibre.com/sites/MLA/search';

export const fetchFromMercadoLibre = async (query, offset = 0, limit = 10) => {
  try {
    const response = await axios.get(
      `${MERCADO_LIBRE_API_URL}?q=${query}&category=MLA412445&offset=${offset}&limit=${limit}&condition=new`
    );

    const transformedResults = response.data.results.map((item) => {
      // Buscar el ISBN dentro de los atributos bajo el identificador 'GTIN'
      const isbnAttribute = item.attributes.find(attr => attr.id === "GTIN");
      const isbn = isbnAttribute ? isbnAttribute.value_name : "ISBN no disponible";

      // Intentar extraer la editorial del título si está presente
      const editorial = item.title.match(/Editorial\s([^\s]+)/i)?.[1] || "Editorial no disponible";

      return {
        volumeInfo: {
          title: item.title,
          authors: [item.seller?.nickname || 'Vendedor no disponible'],
          publishedDate: "Fecha no disponible",
          imageLinks: { thumbnail: item.thumbnail || item.thumbnail_id },
          infoLink: item.permalink,
          isbn: isbn, // Agregar el ISBN encontrado
          publisher: editorial, // Agregar la editorial extraída del título
          source: "MercadoLibre"
        },
        saleInfo: {
          listPrice: { amount: item.price },
        },
      };
    });

    return {
      books: transformedResults,
      totalItems: response.data.paging.total || 0
    };
  } catch (error) {
    console.error('Error fetching from MercadoLibre:', error);
    return { books: [], totalItems: 0 };
  }
};
